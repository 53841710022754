<template>
  <div>
    <div
      v-if="!loading"
    >
      <div
        class="d-flex flex-wrap align-items-center flex-md-nowrap flex-md-row"
        style="row-gap: .5rem; column-gap: 1rem;"
      >
        <b-button
          variant="primary"
          size="sm"
          class="text-nowrap"
          @click="addNewModal"
        >
          <feather-icon icon="PlusIcon" />

          {{ $t('Add') }}
        </b-button>

        <h4
          v-if="$slots.title"
          class="mb-0"
        >
          <slot name="title" />
        </h4>
      </div>

      <app-collapse
        v-if="getChecklist && getChecklist.length > 0"
        class="mt-1"
        type="margin"
      >
        <app-collapse-item
          v-for="(category, i) in getChecklist"
          :key="i"
          :title="category.name | textIsland(isMobile ? 24 : 58)"
          :is-visible="isVisible"
        >
          <template #title>
            <div
              class="d-flex align-items-center"
              style="gap: .25rem"
            >
              <b-button
                v-b-tooltip.hover.v-danger
                variant="flat-danger"
                size="sm"
                class="btn-icon p-25"
                :title="$t('Delete')"
                @click="deleteChecklist(category.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              {{ category.name | textIsland(isMobile ? 24 : 58) }}
            </div>
          </template>
          <div
            v-for="(time, index) in category.list"
            :key="index"
          >
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center flex-wrap flex-md-nowrap">
              <div
                class="d-flex flex-wrap align-items-center"
                style="column-gap: .34rem"
              >
                <b-form-checkbox
                  v-model="time.checked"
                  variant="primary"
                  :disabled="!time.doneAt || !checkRequiredPermissions([roles.CONTACT_THREAD_CHECKLIST_EDIT])"
                  inline
                  class="mr-0"
                  @change="checkPoint(time.id, i, index, time.datumId, category.id, $event)"
                />

                <h6
                  v-b-tooltip.hover.v-primary
                  class="text-nowrap mb-0"
                  :title="time.name"
                >
                  {{ time.name | textIsland(isMobile ? 24 : 999) }}
                </h6>

                <b-button-group size="sm">
                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="flat-primary"
                    class="btn-icon p-lg-25"
                    :title="time.description"
                    :disabled="!time.description"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>

                  <b-button
                    v-b-tooltip.hover.v-primary
                    variant="flat-primary"
                    class="btn-icon p-lg-25"
                    :title="$t('SeeVideo')"
                    :disabled="!time.url"
                    @click="() => { if (time.url) openModal(time.url) }"
                  >
                    <feather-icon icon="YoutubeIcon" />
                  </b-button>
                </b-button-group>
              </div>

              <div
                v-if="!time.checked"
                class="w-100 ml-md-1"
                style="max-width: max-content"
              >
                <sw-select
                  v-if="checkRequiredPermissions([roles.CONTACT_THREAD_CHECKLIST_EDIT])"
                  style="min-width: 10rem;"
                >
                  <sw-select-fpr
                    :is-empty="time.doneAt"
                    @clear="time.doneAt = ''"
                  >
                    <flat-pickr
                      :key="`checklist_${index}_${locale}`"
                      v-model="time.doneAt"
                      :placeholder="$t('ExecutionDate')"
                      class="form-control"
                      :config="{ ...timepicker, locale: getCalendarLocale($i18n.locale) }"
                    />
                  </sw-select-fpr>
                </sw-select>

                <div v-else>
                  <b-form-input
                    disabled
                    :placeholder="$t('ExecutionDate')"
                  />
                </div>
              </div>

              <div
                v-else
                class="d-flex align-items-center"
              >
                <span>
                  <feather-icon icon="ClockIcon" />
                  {{ time.doneAt }}
                </span>

                <span class="ml-50">
                  <b-avatar
                    v-if="time.updatedBy"
                    v-b-tooltip.hover
                    :title="`${time.updatedBy.firstName} ${time.updatedBy.lastName}`"
                    class="pull-up"
                    :src="time.updatedBy.avatar"
                    :text="`${time.updatedBy.firstName[0]} ${time.updatedBy.lastName[0]}`"
                    variant="light-primary"
                    size="16"
                  />
                </span>
              </div>
            </div>
            <hr>
          </div>
        </app-collapse-item>
      </app-collapse>

      <span
        v-else
        class="d-flex justify-content-center my-1 text-primary"
      >
        {{ $t('EmptyArray') }}
      </span>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import { GET_CONTACT_CHECKLIST } from '@/@constants/mutations'
import * as roles from '@/helpers/permissions'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

const { OPEN_MODAL } = require('@/@constants/mutations')

export default {
  name: 'Checklist',
  components: {
    flatPickr,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    thread: {
      type: Object,
      required: false,
      default: () => {},
    },
    contactId: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    loading: true,
    roles,
    points: [],
    checklists: [],
    checklistItems: [],
    threadChecklist: '',
    timepicker: {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      time_24hr: true,
      locale: [pl, en],
    },
  }),
  mounted() {
    this.init()
  },
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      currentContact: 'contact/contact',
      selectedThread: 'contact/thread',
      checklist: 'singleContact/getContactChecklist',
      currentUser: 'auth/getCurrentUser',
    }),
    locale() {
      return this.$i18n.locale
    },
    getChecklist() {
      const checklist = this.contactId ? this.checklistItems : this.checklist
      return (checklist || []).sort((a, b) => a.position - b.position)
    },
  },
  watch: {
    modalConfig: {
      deep: true,
      handler(newValue) {
        if (!newValue.isModalOpen) this.$emit('reload')
      },
    },
  },
  methods: {
    async init() {
      if (this.checkRequiredPermissions([roles.CONTACT_THREAD_CHECKLIST_VIEW_ALL])) {
        this.loading = true
        const contactId = this.$route.params?.contactId || this.contactId || this.currentContact.id
        let threadItem = null
        if (this.thread || this.selectedThread) {
          const resp = await axiosIns.get(`1/contacts/${contactId}/threads/${this.thread?.id || this.selectedThread.id}`, { params: { fields_load: this.$fields.CONTACT_SINGLE_THREAD } })
          if (resp) {
            threadItem = resp?.data?.data?.item || null
          }
        }

        this.$store.dispatch(`singleContact/${GET_CONTACT_CHECKLIST}`, { contactId, thread: threadItem })
          .then(res => {
            this.checklistItems = res
          })
          .finally(() => {
            this.loading = false
          })

        this.threadChecklist = this.thread?.contactThreadChecklistTypes || this.selectedThread?.contactThreadChecklistTypes
      }
    },
    addNewModal() {
      const vm = this
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addChecklistModal',
          modalTitle: this.$i18n.t('Add'),
          okButtonTitle: this.$i18n.t('Add'),
          contactId: this.$route.params.contactId || this.contactId || this.currentContact.id,
          thread: this.thread || this.selectedThread,
          callback: () => {
            vm.init()
          },
        })
    },

    deleteChecklist(checklistId) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        .then(() => this.deleteChecklistProcess(checklistId))
    },

    deleteChecklistProcess(checklistId) {
      const { thread, selectedThread } = this
      const contactId = this.$route.params?.contactId || this.contactId || this.currentContact.id

      const newChecklist = [...(thread || selectedThread).contactThreadChecklistTypes.map(el => Number(el.id))].filter(el => el !== Number(checklistId))

      axiosIns.patch(`1/contacts/${contactId}/threads`, {
        id: (thread || selectedThread).id.toString(),
        contactThreadChecklistTypes: newChecklist,
      })
        .then(() => {
          this.showToast('success', this.$i18n.t('RemovedSuccessfully'))

          this.checklistItems = newChecklist
          this.$store.commit('singleContact/GET_CONTACT_CHECKLIST', newChecklist)
          this.$emit('reload')
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    checkPoint(pointId, categoryIndex, fieldIndex, existedId, checklistId, value) {
      console.log('SD', this.checklist[categoryIndex], this.checklist[categoryIndex][fieldIndex])

      const threadId = this.thread?.id || this.selectedThread?.id
      const contactId = this.$route.params?.contactId || this.contactId || this.currentContact.id
      const datumItem = this.checklist.find(el => el.id === checklistId).list[fieldIndex]
      const data = {
        contactThreadChecklistTypePoint: pointId,
        checked: value,
        doneAt: datumItem.doneAt && value ? datumItem.doneAt : null,
      }

      this.$emit('check-point', this.checklist.filter(item => item.checked).length)

      if (existedId) data.id = existedId.toString()

      axiosIns.put(`1/contacts/${contactId}/threads/${threadId}/checklistType/${checklistId}/pointDatum`, data)
        .then(res => {
          try {
            this.checklist[categoryIndex].list[fieldIndex].updatedBy = this.currentUser
            this.checklist[categoryIndex].list[fieldIndex].datumId = res.data.data.items[0].id
            this.$forceUpdate()

            this.$emit('reload')
          } catch (err) {
            console.error(err)
          }
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    openModal(url) {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'cinema',
        modalTitle: this.$i18n.t('offer.website.Video'),
        size: 'lg',
        data: { url },
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
