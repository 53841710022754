<template>
  <applications
    :contact="{ ...contact, contactThread: thread }"
  />
</template>

<script>
import Applications from '@/pages/applications/Applications.vue'
import { mapGetters } from 'vuex'

export default {
  components: { Applications },
  computed: {
    ...mapGetters({
      contact: 'contact/contact',
    }),
  },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
